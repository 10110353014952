import React, { useEffect, useMemo } from "react";
import { List } from 'antd';
import "./team.scss"
import { gql, useQuery } from '@apollo/client'
import Footer from '../../components/Footer'
import $ from 'jquery';

const LIST_TEAM = gql`
    query listTeam {
        teamCollection(order: [weight_DESC]) {
            items {
                name
                title
                description
            }
        }
    }
`

const TeamPage = (props) => {
    const { data: teamData } = useQuery(LIST_TEAM)

    const teams = useMemo(() => {
        if (teamData) {
            return teamData.teamCollection.items
        }

        return []
    }, [ teamData ])

    useEffect(() => {
        $(".header").removeClass("home-nav")
    }, [])

    return (
        <React.Fragment>
            <div className="team-page">
                <div className="mainContent">
                    {teams.map((team, index) =>
                        <div key={index} className="trails-text" >
                            <div>
                                <List itemLayout="vertical" >
                                    <List.Item>
                                        <div key={team.name}>
                                            <h3 className="heading">
                                                <span className="contact-header-text">{team.title}</span>
                                            </h3>
                                            
                                            <h3 className="heading">{team.name}</h3>

                                            <div className="list_description">{team.description}</div>
                                        </div>
                                    </List.Item>
                                </List>
                            </div>
                        </div>
                    )}

                </div>

                <Footer className="light" />
            </div>
        </React.Fragment >
    )
}
export default TeamPage
